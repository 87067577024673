import cn from "classnames";

import { AddIcon } from "./icons/AddIcon";
import { AppearanceIcon } from "./icons/AppearanceIcon";
import { ArrowLeftIcon } from "./icons/ArrowLeftIcon";
import { ArrowRightIcon } from "./icons/ArrowRightIcon";
import { BookmarkSelectedIcon } from "./icons/BookmarkSelectedIcon";
import { BookmarkUnselectedIcon } from "./icons/BookmarkUnselectedIcon";
import { CaretDownIcon } from "./icons/CaretDownIcon";
import { CaretLeftIcon } from "./icons/CaretLeftIcon";
import { CaretRightIcon } from "./icons/CaretRightIcon";
import { CaretUpIcon } from "./icons/CaretUpIcon";
import { CheckmarkIcon } from "./icons/CheckmarkIcon";
import { CloseIcon } from "./icons/CloseIcon";
import { EditIcon } from "./icons/EditIcon";
import { FollowSelectedIcon } from "./icons/FollowSelectedIcon";
import { FollowUnselectedIcon } from "./icons/FollowUnselectedIcon";
import { MembershipIcon } from "./icons/MembershipIcon";
import { MenuIcon } from "./icons/MenuIcon";
import { NewsmixIcon } from "./icons/NewsmixIcon";
import { PushIcon } from "./icons/PushIcon";
import { RefreshIcon } from "./icons/RefreshIcon";
import { SearchIcon } from "./icons/SearchIcon";
import { SettingsIcon } from "./icons/SettingsIcon";
import { ShareIcon } from "./icons/ShareIcon";
import { SignInIcon } from "./icons/SignInIcon";
import { SignOutIcon } from "./icons/SignOutIcon";
import styles from "./Icon.module.scss";
import { BackIcon } from "./icons/BackIcon";
import { Checkbox } from "./icons/Checkbox";
import { CheckboxSelected } from "./icons/CheckboxSelected";
import { NewsletterIcon } from "./icons/NewsletterIcon";
import { WikipediaIcon } from "./icons/WikipediaIcon";
import { DotsIcon } from "./icons/DotsIcon";

type IconVariant = keyof typeof ICON_MAP;

interface IconProps {
  variant: IconVariant;
  size?: "small" | "normal" | "medium" | "large" | "dynamic";
  className?: string;
  ariaLabel?: string;
  inPrimaryButtonContext?: boolean;
}

const ICON_MAP: Record<
  string,
  ({ ariaLabel }: { ariaLabel?: string }) => JSX.Element
> = {
  add: AddIcon,
  appearance: AppearanceIcon,
  arrowLeft: ArrowLeftIcon,
  arrowRight: ArrowRightIcon,
  bookmarkSelected: BookmarkSelectedIcon,
  bookmarkUnselected: BookmarkUnselectedIcon,
  caretLeft: CaretLeftIcon,
  caretRight: CaretRightIcon,
  caretUp: CaretUpIcon,
  caretDown: CaretDownIcon,
  checkbox: Checkbox,
  checkboxSelected: CheckboxSelected,
  checkmark: CheckmarkIcon,
  close: CloseIcon,
  dots: DotsIcon,
  edit: EditIcon,
  followSelected: FollowSelectedIcon,
  followUnselected: FollowUnselectedIcon,
  membership: MembershipIcon,
  menu: MenuIcon,
  newsmix: NewsmixIcon,
  share: ShareIcon,
  push: PushIcon,
  refresh: RefreshIcon,
  search: SearchIcon,
  settings: SettingsIcon,
  signin: SignInIcon,
  signout: SignOutIcon,
  back: BackIcon,
  newsletter: NewsletterIcon,
  wikipedia: WikipediaIcon,
};

const Icon = ({
  variant,
  size = "normal",
  className,
  inPrimaryButtonContext,
  ariaLabel,
}: IconProps) => {
  const IconElement = ICON_MAP?.[variant];

  return IconElement ? (
    <i
      className={cn([
        styles.icon,
        styles[size],
        inPrimaryButtonContext && styles.inPrimaryButton,
        className ? className : styles.iconColor,
      ])}
    >
      <IconElement ariaLabel={ariaLabel} />
    </i>
  ) : null;
};

export { Icon, ICON_MAP };

export type { IconVariant };
