const DotsIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      aria-label={ariaLabel}
    >
      <path
        fill="#757575"
        fillRule="evenodd"
        d="M4.235 9.856a1 1 0 1 0 0-2 1 1 0 0 0 0 2M8.235 9.856a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12.235 9.856a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
        clipRule="evenodd"
      />
      <path
        fill="#757575"
        fillRule="evenodd"
        d="M8.235 15.856a7 7 0 1 0 0-14 7 7 0 0 0 0 14m0 1a8 8 0 1 0 0-16 8 8 0 0 0 0 16"
        clipRule="evenodd"
      />
    </svg>
  );
};

export { DotsIcon };
