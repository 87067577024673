const NewsletterIcon = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <path
        fill="#222"
        fill-rule="evenodd"
        d="M1.696 4.022a.196.196 0 0 0-.196.195v7.566c0 .108.088.195.196.195h12.608a.196.196 0 0 0 .196-.195V4.217a.196.196 0 0 0-.196-.195zM0 4.217c0-.936.76-1.695 1.696-1.695h12.608c.937 0 1.696.759 1.696 1.695v7.566c0 .936-.76 1.695-1.696 1.695H1.696A1.695 1.695 0 0 1 0 11.783z"
        clip-rule="evenodd"
      />
      <path
        fill="#222"
        fill-rule="evenodd"
        d="M2.18 5.495a.55.55 0 0 1 .76-.163L8 8.606l5.06-3.274a.55.55 0 1 1 .598.923l-5.36 3.468a.55.55 0 0 1-.597 0L2.343 6.255a.55.55 0 0 1-.163-.76m3.145 3.493c.151.264.06.6-.204.75L2.914 11a.55.55 0 0 1-.546-.955l2.207-1.26a.55.55 0 0 1 .75.204m5.35-.001a.55.55 0 0 1 .75-.205l2.207 1.261a.55.55 0 1 1-.546.955l-2.207-1.26a.55.55 0 0 1-.204-.751"
        clip-rule="evenodd"
      />
    </svg>
  );
};

export { NewsletterIcon };
